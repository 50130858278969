@import "./components/Gallery/newGallery.scss";
@import "./components/Gallery/queriesList.scss";
@import "./components/modal/editDialog.scss";
@import "./components/codeSnippet.scss";

.tag-active, .tag-active:hover {
	background-color: #ddd;
}
.nav-active {
	color: #000 !important;
}
.dropdown-toggler {
	color: rgba(0,0,0,.5);
	border: 1px solid #fff;
	padding: 0.5rem;
	&:hover {
		border: 1px solid #ececec;
    	border-radius: 50%;
		color: rgba(0,0,0,.7);
		background-color: #ececec;
	}
}
body {
	overflow: hidden;
}
.cursor-pointer {
	cursor: pointer;
}
.item_high {
	z-index: 9;
}
.oyh {
	overflow-y: hidden;
}
.dashboard__item__menu {
	.dropdown-toggle {
		&::after {
			content: none;
		}
	}
}
.md__render {
	height: 100%;
	overflow-y: auto;
}
.block__content {
	display: flex;
	overflow-y: hidden;
	flex: 1;
	border: 1px solid #dfdfdf;
	> .CodeMirror.cm-s-graphiql {
		overflow: hidden;
		height: auto;
	}
}
.grid-stack { 
	background: #FAFAD2; 
	width: 100%;
	height: 100% !important;
}
.grid-stack-item-content { 
	background-color: #18BC9C; 
	/* width: 500px !important;
	height: 500px !important;*/
	overflow: hidden !important;
	/* min-width: 300px !important;
	min-height: 300px !important; */
}
.react-grid-layout {
	height: 100% !important;
	.react-grid-item > .react-resizable-handle.react-resizable-handle {
		&-sw {
			bottom: -5px;
			left: -5px;
		}
		&-nw {
			top: -5px;
			left: -5px;
		}
		&-se {
			bottom: -5px;
			right: -5px;
		}
		&-ne {
			top: -5px;
			right: -5px;
		}
	}
}
.react-grid-item {
	display: flex;
	flex-direction: column;
}
.App {
	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding-top: 5px;
}
#switchview {
	// display: none;
}
.gallery__switch {
	width: 100%;
	&__label {
		width: 100%;
	}
	.myqueries, .allqueries {
		display: inline-flex;
		justify-content: center;
		color: #555;
		  align-items: center;
		width: 50%;
		height: 25px;
		background-color: #f0f0f0;
	}
	#switchview {
		&:not(:checked) + label .myqueries {
			cursor: pointer;
			background-color: #d1d1d1;
		}
		&:checked + label .allqueries {
			cursor: pointer;
			background-color: #d1d1d1;
		}
	}
}
@mixin endpointURL {
	font-size: 14px;
	padding: 10px;
	border-radius: 5px;
	border: none;
	outline: none;
}
.p-modal {
	padding: 10px 0;
	color: #555;
	+.required {
		position: absolute;
		left: 0;
		bottom: 0;
		color: #d50000;
	}
}
.flex {
	display: flex;
	&-col {
		flex-direction: column;
	}
}
.reset__password {
	position: relative;
}
.logo {
	position: relative;
	display: block;
	left: 30px;
	cursor: pointer;
}
.close {
	opacity: 0.5;
	position: absolute;
	left: 5px;
	top: 5px;
	transition: opacity 150ms;
	cursor: pointer;
	&:hover{
		opacity: 1;
	}
}
.open {
	padding: 5px;
	opacity: 0.5;
	transition: opacity 150ms;
	cursor: pointer;
	&:hover{
		opacity: 1;
	}
}
.content {
	position: relative;
	height: 100%;
	width: 100vw;
	overflow: hidden;
	.gallery {
		transition: width 500ms;
		position: relative;
		max-width: 22px;
		&__close {
			opacity: 0.5;
			position: absolute;
			left: 5px;
			top: 15px;
			transition: opacity 150ms;
			cursor: pointer;
			&:hover{
				opacity: 1;
			}
		}
		&__change {
			opacity: 0.5;
			position: absolute;
			left: 30px;
			top: 15px;
			transition: opacity 150ms;
			cursor: pointer;
			&:hover{
				opacity: 1;
			}
		}
		&__toggle {
			opacity: 0.5;
			position: relative;
			left: -10px;
			top: 0;
			transition: opacity 150ms;
			cursor: pointer;
			&:hover{
				opacity: 1;
			}
		}
		.list-group {
			overflow: auto;
			&-item {
				display: flex;
				flex: 1 0 auto;
			}
			&-item:first-child {
				border-top: 0;
			}
			&::-webkit-scrollbar {
				display: none;
			}
		}
		&__queries {
			overflow: auto;
			text-align: left;
			&::-webkit-scrollbar {
				display: none;
			}
		}
		&__query {
			&__control {
				&:not(&:last-child) {
					margin-right: 0.5em;
				}
				i {
					font-size: inherit;
				}
			}
			&__wrapper {
				width: 100%;
				&>i {
					margin-right: .5rem;
				}
				&>.icon {
					margin-right: .5rem;
				}
				button {
					opacity: 0;
				}
				a {
					cursor: pointer;
					max-width: 90%;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			position: relative;
			word-break: break-all;
			font-size: 12px;
			padding: 0 0 0 5px;
			cursor: pointer;
			color: #555;
			p {
				padding: 3.5px 0;
				&:hover {
					color: #555;
				}
			}
			&:hover {
				color: #555;
				background-color: #fff;
				padding: 5px 5px;
			}
		}
		&__header {
			display: none;
			text-align: center;
			align-items: center;
			z-index: 1;
			justify-content: center;
			.nav {
				margin-top: 2px;
				justify-content: center;
				&-tabs {
					width: 100%;
				}
			}
			&__title {
				margin-top: 11px;
			}
		}
		&.active {
			flex-basis: 15%;
			min-width: 300px;
			max-width: 300px;
			.gallery__header {
				display: flex;
			}
		}
	}
}
.graphiql__wrapper {
	display: none;
	flex: 1 1 auto;
	max-width: calc(100% - 300px);
	&.fullwidth {
		max-width: 100%;
	}
	&_active {
		display: flex;
		flex-direction: column;
	}
	&_wide {
		max-width: 100%;
	}
}
.graphiql-explorer-root {
	font-size: 16px !important;
	font-family: monospace !important;
}
.graphiql-container {
	flex: 1 1 90%;
	caret-color: #d7d7d7;
	.endpointURL {
		@include endpointURL ;
		display: flex;
		flex: 1 1 auto;
		text-align: left;
	}
	.toolbar {
		width: 100% ;
	}
	&_active {
		display: block;
	}
	.doc-explorer-title-bar{
		height: auto !important;
	}
	.topBar {
		height: 54px !important;
		background: transparent !important;
	}
	.editorWrap {
		overflow-y: hidden;
	}
}
.docs_icon {
	height: 35px;
	width: 25px;
	padding: 10px 5px;
	cursor: pointer;
	border-radius: 5px;
	&.active {
		background-color: #ececec;
	}
	&:hover {
		background-color: #ececec;
	}
}
.bi-code-slash {
	cursor: pointer;
	padding: 5px;
	border-radius: 5px;
	&.active {
		background-color: #ececec;
	}
	&:hover {
		background-color: #ececec;
	}
}
.tabs {
	flex: 1;
	&__add {
		padding: 10px 10px 0;
	}
	&__edit {
		border: none;
		border-bottom: 1px solid #d7d7d7;
		outline: none;
		height: 100%;
		& + i {
			opacity: .75;
			transition: opacity 100ms;
			&:hover{
				opacity: 1;
			}
		}
	}
	.tab {
		&__edit {
			display: none;
			margin-left: 5px;
		}
		&__close{
			margin-left: 15px;
			padding-left: 12px;
			&:hover:before {
				background-color: #ececec;
				border-radius: 5px;
			}
		}
		&__add {
			opacity: 0.5;
		}
	}
	.nav {
		&-link {
			.cursor-edit {
				cursor: url('https://cdn.jsdelivr.net/gh/bitquery/graphql-ide@1.1.19/src/assets/images/pencil-alt-solid.svg') 0 20, auto;
			}
			&:hover {
				.tab__close {
					opacity: .75;
				}
			}
			& .tab__close {
				opacity: 0;
			}
			&.active {
				.tab__close {
					opacity: .75;
				}
			}
		}
		&-item {
			&:hover {
				.tab__edit_active {
					display: inline-block;
				}
			}
		}
	}
}
.controlpanel {
	position: relative;
	display: flex;
	align-items: flex-start;
	border-bottom: 1px solid #dee2e6;
	position: relative;
	.endpoint_url {
		position: absolute;
		top: 42px;
		left: 460px;
		input {
			text-align: left;
			width: 200%;
			border: none;
			color: #d7d7d7;
		}
	}
	&-fullscreen {
		height: 0;
	}
}
.link__hire {
	padding: 0 .5rem;
	&:hover {
		text-decoration: none;
	}
}
.profile{
	align-items: center;
	flex-direction: row-reverse;
	justify-content: flex-end;
	position: relative;
	margin: 0 5px 0 0;
	&__menu {
		justify-content: center;
		padding-bottom: 5px;
		&.dropdown{
			height: 40px;
			display: flex;
		}
		.dropdown-toggle {
			&::after {
				content: none;
			}
		}
	}
	&__email {
		margin: auto;
	}
	&__button {
		background-color: #555;
		color: #d7d7d7;
		a {
			color: #d7d7d7;
		}
		padding: 10px;
		&:hover {
			background-color:  #828282;
		}
	}
	&__controls {
		background-color: #555;
		-webkit-box-shadow: 0px 4px 3px 4px rgba(0,0,0,0.46); 
		box-shadow: 0px 4px 3px 4px rgba(0,0,0,0.46);
		border-top: none;
		width: 150px;
		z-index: 3;
		position: absolute;
		display: none;
		top: 35px;
		right: 0;
		&.active {
			display: flex;
		}
	}
	&__image {
		font-size: 30px;
		margin: auto 5px;
		cursor: pointer;
		opacity: 0.5;
		&#dropdownMenuButton{
			opacity: 1;
		}
	}
	img {
		padding: 0 10px;
		cursor: pointer;
		width: 55px;
	}
}
.button {
	cursor: pointer;
	width: 150px;
	background: none;
	color: #555;
	padding: 8px;
	margin: 0 5px 0 0;
	border-radius: 10px;
	outline: none;
	&:hover {
		background-color: #d5d5d5;
	}
	&_filled {
		background-color: #d1d1d1;
		border: 1px solid #c27a7a38;
		margin: 35px auto 0;
	}
}
.query {
	&__save {
		@include endpointURL;
		border-bottom: 1px solid #aaa;
	}
}
.dashboard {
	display: none;
	&.active {
		overflow-y: scroll;
		display: block;
		height: 100%;
	}
}
.modal {
	display: flex;
	flex-direction: column;
	&__form {
		.access-control{
			&__wrapper {
				width: 80%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				.query-link{
					width: 80%;
				}
			}
		}
		&__options{
			margin: 10px 0 0 0;
			width: 100%;
			justify-content: space-between;
		}
		.handler {
			cursor: pointer;
			position: absolute;
			opacity: 0.25;
			transition: opacity 150ms;
			&:hover {
				opacity: 1;
			}
			&__back {
				width: 50px;
				height: 50px;
				padding: 10px;
				left: 10px;
				bottom: 0;
				transform: translateY(-50%);
			}
			&__close {
				width: 12px;
				height: 12px;
				top: 10px;
				right: 10px;
			}
		}
		input[type="text"], input[type="password"], textarea {
			text-align: left;
			width: 100%;
		}
		h2 {
			font-size: 25px;
			padding: 15px 0 15px 0;
			color: #555;
		}
		display: flex;
		flex-direction: column;
		align-items: center;
		&_hide {
			display: none;
		}
		a {
			cursor: pointer;
			padding-top: 10px;
			width: fit-content;
			color: #555;
			font-size: 16px;
			&:hover {
				color: #007bff;
			}
		}
	}
}
.profile-toggler:hover {
	background-color: #ececec;
}
.metrics__info {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	border: 1px solid #cecece;
	top: 10%;
	padding: 30px;
	min-width: 200px;
	max-width: 70%;
	min-height: 100px;
	border-radius: 10px;
}
.statisticsTable {
	tr:nth-child(even) {
		td:before {
			background-color: #fff;
		}
	}
	.metrics {
		&__helper {
			background: linear-gradient(#f9f9f9,#ececec);
			box-shadow: inset 0 0 0 1px rgb(0 0 0 / 20%), 0 1px 0 hsl(0deg 0% 100% / 70%), inset 0 1px #fff;
		    color: #555;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			text-align: center;
			display: inline-block;
			line-height: 20px;
			user-select: none;
			margin-right: 5px;
		}
	}
	td {
		position: relative;
		vertical-align: inherit;
		z-index: 0;
	}
	tbody {
		td:nth-child(2):after {
			content: "";
			display: block;
			z-index: -2;
			top: 10%;
			left: 0;
			width: 100%;
			height: 80%;
			position: absolute;
			background: rgb(175,245,180);
			background: linear-gradient(90deg, rgba(175,245,180,1) 0%, rgba(243,244,151,1) 50%, rgba(255,143,143,1) 100%);
		}
	}
	td:nth-child(2):before {
		content: "";
		display: block;
		position: absolute;
		z-index: -1;
		top: 0;
		width: calc(100% - var(--part));
		left: var(--part);
		height: 100%;
		background-color: rgb(240, 240, 240);
	}
}
/* .navbar-collapse {
	flex-grow: 0;
	flex-basis: 0%;
	margin-right: 20px;
} */
.navbar-brand {
	font-size: 1rem;
}
.icon {
	&_user {
		color: red;
		width: 35px;
		height: 35px;
		margin: 0 5px;
		cursor: pointer;
		opacity: .5;
		&#dropdownMenuButton{
			opacity: 1;
		}
	}
	&_play {
		width: 80%;
    	transform: translateX(4px);
	}
}
.confirmation-modal {
	.modal-content {
		width: 70%;
		padding: 1rem;
		margin: 0 auto;
		box-shadow: 0px 0px 25px 0px #000000;
	}
}
//Doc Explorer

.doc-explorer {
	position: relative;
    width: 33%;
    height: 100%;
    overflow: auto;
	background: white;
	border-left: 1px solid #d0d0d0;
  }
  
  .doc-explorer-title-bar,
  .history-title-bar {
	cursor: default;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 34px;
	line-height: 14px;
	padding: 8px 8px 5px;
	position: relative;
	user-select: none;
  }
  
  .doc-explorer-title,
  .history-title {
	flex: 1;
	overflow-x: hidden;
	padding: 10px 0 10px 10px;
	text-align: center;
	text-overflow: ellipsis;
	user-select: text;
	white-space: nowrap;
  }
  
  .doc-explorer-back {
	color: #3B5998;
	cursor: pointer;
	margin: -7px 0 -6px -8px;
	overflow-x: hidden;
	padding: 17px 12px 16px 16px;
	text-overflow: ellipsis;
	white-space: nowrap;
	background: 0;
	border: 0;
	line-height: 14px;
  }
  
  .doc-explorer-narrow .doc-explorer-back {
	width: 0;
  }
  
  .doc-explorer-back:before {
	border-left: 2px solid #3B5998;
	border-top: 2px solid #3B5998;
	content: '';
	display: inline-block;
	height: 9px;
	margin: 0 3px 1px 0;
	position: relative;
	transform: rotate(-45deg);
	width: 9px;
  }
  
  .doc-explorer-rhs {
	position: relative;
  }
  
  .doc-explorer-contents,
  .history-contents {
	background-color: #ffffff;
	border-top: 1px solid #d6d6d6;
	bottom: 0;
	left: 0;
	overflow-y: auto;
	padding: 20px 15px;
	position: absolute;
	right: 0;
	top: 47px;
  }
  
  .doc-explorer-contents {
	min-width: 300px;
  }
  
  .doc-type-description p:first-child ,
  .doc-type-description blockquote:first-child {
	margin-top: 0;
  }
  
  .doc-explorer-contents a {
	cursor: pointer;
	text-decoration: none;
  }
  
  .doc-explorer-contents a:hover {
	text-decoration: underline;
  }
  
  .doc-value-description > :first-child {
	margin-top: 4px;
  }
  
  .doc-value-description > :last-child {
	margin-bottom: 4px;
  }
  
  .doc-type-description code,
  .doc-type-description pre,
  .doc-category code,
  .doc-category pre {
	--saf-0: rgba(var(--sk_foreground_low,29,28,29),0.13);
	font-size: 12px;
	line-height: 1.50001;
	font-variant-ligatures: none;
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
	word-break: normal;
	-webkit-tab-size: 4;
	-moz-tab-size: 4;
	tab-size: 4;
  }
  
  .doc-type-description code,
  .doc-category code {
	padding: 2px 3px 1px;
	border: 1px solid var(--saf-0);
	border-radius: 3px;
	background-color: rgba(var(--sk_foreground_min,29,28,29),.04);
	color: #e01e5a;
	background-color: white;
  }
  
  .doc-category {
	margin: 20px 0;
  }
  
  .doc-category-title {
	border-bottom: 1px solid #e0e0e0;
	color: #777;
	cursor: default;
	font-size: 14px;
	font-variant: small-caps;
	font-weight: bold;
	letter-spacing: 1px;
	margin: 0 -15px 10px 0;
	padding: 10px 0;
	user-select: none;
  }
  
  .doc-category-item {
	margin: 12px 0;
	color: #555;
  }
  
  .keyword {
	color: #B11A04;
  }
  
  .type-name {
	color: #CA9800;
  }
  
  .field-name {
	color: #1F61A0;
  }
  
  .field-short-description {
	color: #999;
	margin-left: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
  }
  
  .enum-value {
	color: #0B7FC7;
  }
  .nav-link {
	  display: flex;
	  align-items: center;
	  &-add {
		  height: 100%;
	  }
	  &-title {
		display: inline-block;
		white-space: nowrap;
		max-width: 150px;
		overflow: hidden;
		text-overflow: ellipsis;
	  }
	  &>i:not(:last-child) {
		  margin-right: .5rem;
	  }
	  &>.icon:not(:last-child) {
		  margin-right: .5rem;
	  }
  }
  .nav-link>i:not(:last-child) {
	  margin-right: .5rem;
  }
  .item-container {
	  border: 1px solid #dfdfdf;
	  width: 100%;
	  height: 100%;
	  overflow-y: hidden;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  &.tabulator {
		  display: block;
	  }
  }
  .arg-name {
	color: #8B2BB9;
  }
  
  .arg {
	display: block;
	margin-left: 1em;
  }
  
  .arg:first-child:last-child,
  .arg:first-child:nth-last-child(2),
  .arg:first-child:nth-last-child(2) ~ .arg {
	display: inherit;
	margin: inherit;
  }
  
  .arg:first-child:nth-last-child(2):after {
	content: ', ';
  }
  
  .arg-default-value {
	color: #43A047;
  }
  
  .doc-deprecation {
	background: #fffae8;
	box-shadow: inset 0 0 1px #bfb063;
	color: #867F70;
	line-height: 16px;
	margin: 8px -8px;
	max-height: 80px;
	overflow: hidden;
	padding: 8px;
	border-radius: 3px;
  }
  
  .doc-deprecation:before {
	content: 'Deprecated:';
	color: #c79b2e;
	cursor: default;
	display: block;
	font-size: 9px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 1;
	padding-bottom: 5px;
	text-transform: uppercase;
	user-select: none;
  }
  
  .doc-deprecation > :first-child {
	margin-top: 0;
  }
  
  .doc-deprecation > :last-child {
	margin-bottom: 0;
  }
  
  .show-btn {
	-webkit-appearance: initial;
	display: block;
	border-radius: 3px;
	border: solid 1px #ccc;
	text-align: center;
	padding: 8px 12px 10px;
	width: 100%;
	box-sizing: border-box;
	background: #fbfcfc;
	color: #555;
	cursor: pointer;
  }
  
  .search-box {
	border-bottom: 1px solid #d3d6db;
	display: block;
	font-size: 14px;
	margin: -15px -15px 12px 0;
	position: relative;
  }
  
  .search-box-icon {
	cursor: pointer;
	display: block;
	font-size: 24px;
	position: absolute;
	top: -2px;
	transform: rotate(-45deg);
	user-select: none;
  }
  
  .search-box .search-box-clear {
	background-color: #d0d0d0;
	border-radius: 12px;
	color: #fff;
	cursor: pointer;
	font-size: 11px;
	padding: 1px 5px 2px;
	position: absolute;
	right: 3px;
	top: 8px;
	user-select: none;
	border: 0;
  }
  
  .search-box .search-box-clear:hover {
	background-color: #b9b9b9;
  }
  
  .search-box > input {
	border: none;
	box-sizing: border-box;
	font-size: 14px;
	outline: none;
	padding: 6px 24px 8px 20px;
	width: 100%;
  }
  
  .error-container {
	font-weight: bold;
	left: 0;
	letter-spacing: 1px;
	opacity: 0.5;
	position: absolute;
	right: 0;
	text-align: center;
	text-transform: uppercase;
	top: 50%;
	transform: translate(0, -50%);
  }
  .docExplorerHide {
	cursor: pointer;
	font-size: 18px;
	margin: -7px -8px -6px 0;
	padding: 18px 16px 15px 12px;
	background: 0;
	border: 0;
	line-height: 14px;
}
.docExplorerShow {
  background: inherit;
  border: none;
  color: #3B5998;
  cursor: pointer;
  margin: 0;
  padding: 2px 20px 0 18px;
}

.docExplorerShow:before {
  border-left: 2px solid #3B5998;
  border-top: 2px solid #3B5998;
  content: '';
  display: inline-block;
  height: 9px;
  margin: 0 3px 1px 0;
  position: relative;
  transform: rotate(-45deg);
  width: 9px;
}

.main_notification {
	.handler {
		position: absolute;
		right: 0;
		top: 0;
		margin-top: -5px;
		cursor: pointer;
		padding: 15px;
		animation: appears_from_top 1.5s ease;
	}
	p {
		margin-bottom: 0;
	}
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 50px;
	width: 99%;
	z-index: 9;
	margin: -5px auto 0;
	color: #555;
	background: linear-gradient(#f9f9f9, #ececec);
	-webkit-box-shadow: 0px 6px 12px 6px rgba(34, 60, 80, 0.22);
	-moz-box-shadow: 0px 6px 12px 6px rgba(34, 60, 80, 0.22);
	box-shadow: 0px 6px 12px 6px rgba(34, 60, 80, 0.22);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	justify-content: center;
	align-items: center;
	animation: appears_from_top 1.5s ease;
  }
  @keyframes appears_from_top {
	from {
	  margin-top: -50px;
	}
	to {
	  margin-top: -5px;
	}
  }
  .Overlay {
	animation: opac 2s ease;
  }
  @keyframes opac {
	0% {
	  opacity: 0
	}
	100% {
	  opacity: 1
	}
  }