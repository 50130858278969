.querylist {
	&__root {
		position: absolute;
		overflow: auto;
		width: 0;
		height: 100%;
		background-color: #fff;
		z-index: 10;
		right: 0;
		display: flex;
		flex-direction: column;
		transition: width .1s ease-out;
		&.active {
			width: calc(100% - 300px);
		}
		&.fullwidth {
			width: 100%;
		}
	}
	&__buttons {
		justify-content: space-evenly;
		padding: 10px 0;
	}
	&__item {
		&__wrapper {
			display: flex;
			border-bottom: 1px solid black;
			align-items: center;
		}
		font-size: 10px;
		&:hover {
			background-color: rgb(221, 221, 221);
		}
		&__name {
			margin: 0;
			font-size: 20px;
			text-transform: capitalize;
			cursor: pointer;
		}
		&__status {
			padding-right: 10px;
			cursor: default;
		}
		&__header {
			display: flex;
			align-items: center;
			gap: 10px;
		}
		&__footer {
			span {
				font-style: italic;
			}
		}
		display: flex;
		flex-direction: column;
		flex: 1;
		padding: 0 20px;
		&__tag {
			color: rgb(73, 80, 87);
			font-size: 14px;
			margin: 5px 2px;
			background-color: antiquewhite;
		}
	}
}